var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('full-table',{attrs:{"other-filter-props":{
      add:
        _vm.$can('manage', 'id_manager') ||
        _vm.$can('manage', 'admin') ||
        _vm.$can('manage', 'super_admin') ||
        _vm.$can('manage', 'head_manager'),
      search: true,
      gender: true,
      level: true,
      group: true,
      branchs:
        _vm.$can('manage', 'super_admin') || _vm.$can('manage', 'head_manager'),
    },"items":_vm.GET_USERS_LIST.results,"fields":_vm.fields,"rows":_vm.GET_USERS_LIST.count,"edit-btn":false,"first-params":_vm.params,"empty-text":_vm.$t('general.no_data_to_view')},on:{"refresh":_vm.FETCH_USERS_LIST,"delete-button-clicked":_vm.modalDeletePupil,"row-clicked":function (data) { return _vm.$router.push(("/users/" + (data.id) + "/info")); },"detail-button-clicked":function (id) { return _vm.$router.push(("/users/" + id + "/info")); },"add-button-clicked":_vm.addUser}}),_c('add-modal',{attrs:{"default-role":_vm.defRole,"default-params":_vm.params}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }